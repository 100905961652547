// Users.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface User {
    id: number;
    name: string;
    email: string;
    phone_number: string;
    is_admin: boolean; // Include is_admin in the User interface
}

const Users: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get<User[]>('http://127.0.0.1:8000/users/'); // Specify the type of response
                setUsers(response.data); // Set the users state with the fetched data
            } catch (err) {
                console.error(err); // Log the error for debugging
                setError('Failed to fetch users'); // Handle any errors
            } finally {
                setLoading(false); // Set loading to false after the request is complete
            }
        };

        fetchUsers(); // Call the fetch function
    }, []); // Empty dependency array means this effect runs once on mount

    if (loading) {
        return <div>Loading...</div>; // Show loading message while fetching data
    }

    if (error) {
        return <div>{error}</div>; // Show error message if fetching fails
    }

    return (
        <div>
            <h1>Users List</h1>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Admin</th> {/* Add a column for is_admin */}
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone_number}</td>
                            <td>{user.is_admin ? 'Yes' : 'No'}</td> {/* Display is_admin status */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Users;