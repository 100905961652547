import axios from 'axios';
import { Note } from '../components/MobileInterface'; // Ensure this import is correct

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000'; // Fallback to localhost if not set
console.log('API Base URL:', API_BASE_URL);

const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add request interceptor to include the token
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token && config.headers) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

// Fetch notes for the authenticated user
export const fetchNotes = async (): Promise<Note[]> => {
    const response = await api.get('/notes');
    return response.data; // Ensure this returns the correct structure
};

// Fetch a specific note by ID
export const fetchNoteById = async (noteId: number) => {
    const response = await api.get(`/notes/${noteId}`);
    return response.data; // Return the note data
};

// Update a specific note
export const updateNote = async (noteId: number, noteData: { content: string; language?: string; classifications?: string[]; english_summary?: string; content_type?: string; specific_details?: any; }) => {
    const response = await api.put(`/notes/${noteId}`, noteData); // Send updated note data
    return response.data; // Return the updated note data
};

// Process note with LLM
export const processNoteWithLLM = async (noteContent: string) => {
    const response = await api.post('/process-note', { content: noteContent });
    return response.data; // Assuming the LLM returns categorized data
};

// Add the loginUser function
export const loginUser = async (data: { phone_number: string }) => {
    const response = await api.post('/auth/login', data);
    return response.data; // Return the user data, including the token
};

// Add the registerUser function
export const registerUser = async (data: { phone_number: string; name: string; email?: string }) => {
    const response = await api.post('/auth/registration', data);
    return response.data; // Return the newly created user data
};