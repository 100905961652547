// src/components/NotePage.tsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Button } from "./ui/Button";
import Textarea from "./ui/Textarea"; // Corrected import for default export
import { Loader2 } from "lucide-react";
import { fetchNoteById, updateNote } from './api/api'; // Ensure fetchNoteById and updateNote are imported
import { formatNoteContent } from './utils'; // Ensure this path is correct

interface Note {
  id: number;
  content: string;
}

const NotePage: React.FC = () => {
  const { noteId } = useParams<{ noteId: string }>(); // Get the note ID from the URL
  const [note, setNote] = useState<Note | null>(null); // State for the note
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState('');
  const [error, setError] = useState<string | null>(null); // State for error messages
  const navigate = useNavigate();

  useEffect(() => {
    const loadNote = async () => {
      try {
        const fetchedNote = await fetchNoteById(Number(noteId)); // Fetch the specific note
        setNote(fetchedNote);
        setContent(fetchedNote.content); // Set the content for editing
      } catch (error) {
        console.error('Failed to fetch note:', error);
        setError('Failed to load note. Please try again.'); // Set error message
      } finally {
        setIsLoading(false);
      }
    };

    loadNote();
  }, [noteId]);

  const handleUpdate = async () => {
    setIsLoading(true);
    setError(null); // Reset error state before update
    try {
      const updatedNoteData = { content }; // Prepare the updated note data
      await updateNote(Number(noteId), updatedNoteData); // Ensure noteId is converted to a number
      navigate('/notes'); // Redirect to the notes page after successful update
    } catch (error) {
      console.error('Failed to update note:', error);
      setError('Failed to update note. Please try again.'); // Set error message
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Loading state
  }

  if (!note) {
    return <div>Note not found</div>; // Handle case where note is not found
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <Card className="mb-4">
        <CardHeader>
          <CardTitle>Edit Note</CardTitle>
        </CardHeader>
        <CardContent>
          {error && <div className="text-red-500 mb-2">{error}</div>} {/* Display error message */}
          <Textarea 
            placeholder="Edit your content here..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="min-h-[100px] mb-4 resize-none" // Prevent resizing for better mobile experience
            aria-label="Note content" // Accessibility improvement
          />
          <Button onClick={handleUpdate} disabled={isLoading || content.trim() === ''}>
            {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : 'Update Note'}
          </Button>
          <div className="mt-4">
            {/* Render formatted content with links directly */}
            {formatNoteContent(content)}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default NotePage;