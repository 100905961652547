import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card"; // Ensure these imports are correct
import { Button } from "./ui/Button"; // Ensure this import is correct
import { Input } from "./ui/Input"; // Ensure this import is correct
import { Loader2 } from "lucide-react"; // Loader for loading state
import { registerUser } from '../api/api'; // Import the registerUser function

const Register: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const navigate = useNavigate();
  const location = useLocation(); // Use the useLocation hook

  // Prefill phone number from query parameters
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const phone = queryParams.get('phone');
    if (phone) {
      setPhoneNumber(phone);
      console.log("Prefilled phone number from query params:", phone);  // Log the phone number
    }
  }, [location.search]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage(''); // Reset error message

    const requestData = {
      name,
      email,
      phone_number: phoneNumber,
    };

    try {
      const response = await registerUser(requestData); // Use the registerUser function
      console.log('Registration successful:', response.data);
      navigate('/notes'); // Redirect to the notes page after successful registration
    } catch (error: any) {
      console.error('Error during registration:', error);
      // Check if the error response contains the detail message
      if (error.response && error.response.data && error.response.data.detail) {
        // Set the detailed error message and append the "Please return to login." text
        setErrorMessage(`${error.response.data.detail}`);
      } else {
        setErrorMessage('Registration failed. Please try again.'); // Fallback error message
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">Register for KnoteTaker</CardTitle>
        </CardHeader>
        <CardContent>
          {errorMessage && (
            <p className="text-red-500">
              {errorMessage} 
              <a href="/login" className="text-blue-500 underline ml-1">Return to login.</a>
            </p>
          )} {/* Display error message with link */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Input
                id="name"
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="w-full"
              />
              <Input
                id="email"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full"
              />
              <Input
                id="phoneNumber"
                type="tel"
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                className="w-full"
              />
            </div>
            <Button type="submit" className="w-full" disabled={isLoading}>
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Registering...
                </>
              ) : (
                'Register'
              )}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Register;
