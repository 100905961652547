import React from 'react';
import { jwtDecode} from 'jwt-decode';  // Correctly import jwtDecode as a default import

export const formatNoteContent = (content: string) => {
    const locationPattern = /(?:Location:|I like working at|I live in|Address:)\s*([A-Za-z0-9\s,]+(?:,\s*[A-Za-z]{2})?\s*\d{5})/g;

    return content.split(locationPattern).map((part, index) => {
        const match = locationPattern.exec(part);
        if (match && match[1]) {
            const location = match[1].trim();
            const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`;
            return (
                <a key={index} href={googleMapsLink} target="_blank" rel="noopener noreferrer">
                    {location}
                </a>
            );
        }
        return <span key={index}>{part}</span>;
    });
};

interface TokenPayload {
    sub: string; // This assumes the token contains the user ID in the `sub` field
}

export const getUserIdFromToken = (): string | null => {
    const token = localStorage.getItem('token'); // Assuming you're storing the token in localStorage
    if (token) {
        try {
            const decoded: TokenPayload = jwtDecode(token);  // Use jwtDecode correctly
            return decoded.sub;  // Return the user ID from the token
        } catch (error) {
            console.error("Error decoding token:", error);
            return null;
        }
    }
    return null;
};
