import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import App from './App';
import './index.css'; // or your main CSS file

const container = document.getElementById('root');

if (!container) {
    throw new Error("Root container not found"); // Handle the case where the root element is not found
}

const root = createRoot(container); // Create a root

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);