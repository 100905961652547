// src/components/Login.tsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import { Loader2 } from "lucide-react";
import { loginUser } from '../api/api'; // Ensure this points to your login function
import { useAuth } from './contexts/AuthContext'; // Import the AuthContext

const Login: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth(); // Use the login function from context
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // If a token exists, redirect to the /notes page
      navigate('/notes');
    }
  }, [navigate]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null); // Reset error state before login attempt

    try {
      const userData = await loginUser({ phone_number: phoneNumber }); // Call your login function
      if (userData) {
        // Store the token in local storage
        localStorage.setItem('token', userData.access_token); // Adjust based on your API response
        login(userData); // Set the user in context using the login function
        navigate('/notes'); // Redirect to the notes page
      }
    } catch (error: any) {
      console.error('Login failed:', error);
      setError('Login failed. Please try again.'); // Set error message for UI

      // Handle the specific 404 error for unknown users
      if (error.response && error.response.status === 404) {
        console.log("Navigating to /register with phone:", phoneNumber);  // Log the phone number
        navigate(`/register?phone=${encodeURIComponent(phoneNumber)}`);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An unexpected error occurred:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">KnoteTaker Login</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleLogin} className="space-y-4">
            <div className="space-y-2">
              <Input
                id="phoneNumber"
                type="tel"
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                className="w-full"
              />
            </div>
            {error && <div className="text-red-500">{error}</div>} {/* Display error message */}
            <Button type="submit" className="w-full" disabled={isLoading}>
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Logging in...
                </>
              ) : (
                'Login'
              )}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;