import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Button } from "./ui/Button";
import Textarea from "./ui/Textarea"; 
import { Loader2, ChevronDown, ChevronUp } from "lucide-react";
import { fetchNotes, createNote } from './api/api'; 
import { getUserIdFromToken } from './utils'; 

export interface Note {
    id: number;
    content: string;
    language: string;
    classifications: string[];
    english_summary: string | null;
    content_type: string;
    specific_details: any;
    user_id: number;
    created_at: string;
    updated_at: string;
    expanded: boolean;
}

const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit' 
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
};

export default function MobileInterface() {
    const [content, setContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [savedNotes, setSavedNotes] = useState<Note[]>([]);
    const navigate = useNavigate(); 

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        } else {
            const userId = getUserIdFromToken(); // Call without arguments
            console.log('User ID:', userId); // Log the user ID for debugging
            loadNotes();
        }
    }, [navigate]);

    const loadNotes = async () => {
        try {
            const notes = await fetchNotes();
            setSavedNotes(notes.map((note: Note) => ({ ...note, expanded: false })).reverse());
        } catch (error) {
            console.error('Failed to fetch notes:', error);
        }
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            const newNote = await createNote({ content });
            setSavedNotes(prevNotes => [
                { ...newNote, expanded: true },
                ...prevNotes.map(note => ({ ...note, expanded: false }))
            ]);
            setContent('');
            setIsSaved(true);
            setTimeout(() => setIsSaved(false), 3000);
        } catch (error) {
            console.error('Failed to save note:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const toggleExpand = (id: number) => {
        setSavedNotes(savedNotes.map(note => 
            note.id === id ? { ...note, expanded: !note.expanded } : note
        ));
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
            <Card className="w-full max-w-md mb-4">
                <CardHeader>
                    <CardTitle>KnoteTaker</CardTitle>
                </CardHeader>
                <CardContent>
                    <Textarea 
                        placeholder="Enter your content or ideas here..."
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className="min-h-[100px] mb-4" 
                    />
                    <Button onClick={handleSave} disabled={isLoading || content.trim() === ''} className="w-full">
                        {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : 'Save Note'}
                    </Button>
                    {isSaved && <p className="text-green-600 mt-2">Content saved successfully!</p>}
                </CardContent>
            </Card>

            <div className="w-full max-w-md">
                {savedNotes.map((note) => (
                    <Card key={note.id} className="mb-2">
                        <CardHeader className="p-4 cursor-pointer" onClick={() => toggleExpand(note.id)}>
                            <div className="flex justify-between items-center">
                                <h3 className="text-lg font-semibold">{note.content}</h3>
                                {note.expanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                            </div>
                        </CardHeader>
                        {note.expanded && (
                            <CardContent className="p-4">
                                <p className="text-sm"><strong>Created:</strong> {formatDate(note.created_at)}</p>
                            </CardContent>
                        )}
                    </Card>
                ))}
            </div>
        </div>
    );
}
