   // EditNoteComponent.tsx
   import React, { useEffect, useState } from 'react';
   import { useParams, useNavigate } from 'react-router-dom';
   import axios from 'axios';

   const EditNoteComponent: React.FC = () => {
       console.log("EditNoteComponent rendered"); // Add this line
       const { id } = useParams<{ id: string }>(); // Get the note ID from the URL
       const [note, setNote] = useState<any>(null); // State to hold the note data
       const [content, setContent] = useState<string>(''); // State for note content
       const [additionalDetails, setAdditionalDetails] = useState<string>(''); // State for additional details
       const navigate = useNavigate();

       useEffect(() => {
           const fetchNote = async () => {
               try {
                   const response = await axios.get(`/api/notes/${id}`); // Adjust the API endpoint as needed
                   setNote(response.data);
                   setContent(response.data.content);
                   setAdditionalDetails(response.data.additionalDetails || ''); // Set additional details
               } catch (error) {
                   console.error('Error fetching note:', error);
               }
           };

           fetchNote();
       }, [id]);

       // Handle form submission
       const handleSubmit = async (e: React.FormEvent) => {
           e.preventDefault();
           try {
               await axios.put(`/api/notes/${id}`, { content, additionalDetails }); // Send updated data
               navigate(`/notes/${id}`); // Navigate back to the note view after saving
           } catch (error) {
               console.error('Error updating note:', error);
           }
       };

       // Handle cancel action
       const handleCancel = () => {
           navigate('/notes'); // Navigate back to the notes page
       };

       if (!note) return <div>Loading...</div>; // Show loading state while fetching

       return (
           <div style={{ position: 'relative' }}>
               <h1>Edit Note</h1>
               <button 
                   onClick={handleCancel} 
                   style={{ 
                       position: 'absolute', 
                       top: 10, 
                       right: 10, 
                       background: 'red', // Change background color for visibility
                       color: 'white', // Change text color for contrast
                       border: 'none', 
                       borderRadius: '50%', // Make it circular
                       width: '30px', // Set width
                       height: '30px', // Set height
                       cursor: 'pointer', 
                       fontSize: '16px', 
                       lineHeight: '30px', // Center text vertically
                       textAlign: 'center' // Center text horizontally
                   }}
               >
                   X
               </button>
               <form onSubmit={handleSubmit}>
                   <div>
                       <label>Content:</label>
                       <textarea
                           value={content}
                           onChange={(e) => setContent(e.target.value)} // Update content state
                       />
                   </div>
                   <div>
                       <label>Additional Details:</label>
                       <textarea
                           value={additionalDetails} // Bind additionalDetails state
                           onChange={(e) => setAdditionalDetails(e.target.value)} // Update additionalDetails state
                       />
                   </div>
                   <button type="submit">Save Changes</button>
               </form>
           </div>
       );
   };

   export default EditNoteComponent;