//import React, { useState } from 'react';

interface TextareaProps {
    placeholder?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    className?: string;
}

const Textarea: React.FC<TextareaProps> = ({ placeholder, value, onChange, className }) => {
    return (
        <textarea
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={`w-full min-h-[100px] border border-gray-300 rounded-md p-2 ${className}`} // Set width to full
            required
        />
    );
};

export default Textarea;