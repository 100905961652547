// App.tsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MobileInterface from './components/MobileInterface';
import Login from './components/Login';
import Register from './components/Register';
import NotePage from './components/NotePage';
import EditNoteComponent from './components/EditNoteComponent';
import { AuthProvider, useAuth } from './components/contexts/AuthContext';
import Logout from './components/Logout';
import Users from './components/Users';

// Log the API Base URL
const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';
console.log('API Base URL:', API_BASE_URL); // Log the API base URL

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  return user ? <>{children}</> : <Navigate to="/login" />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/notes/:noteId" element={<PrivateRoute><NotePage /></PrivateRoute>} />
          <Route path="/" element={<PrivateRoute><MobileInterface /></PrivateRoute>} />
          <Route path="/notes" element={<MobileInterface />} />
          <Route path="/notes/:id" element={<EditNoteComponent />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} /> {/* Route for Users */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App; // Ensure you have this line for default export
