import axios from 'axios';

const API_BASE_URL = 'http://localhost:8000';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token && config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export const registerUser = async ({ name, email, phone_number }: { name: string; email: string; phone_number: string }) => {
  const response = await api.post('/auth/register', { name, email, phone_number });
  return response.data; // Return the response data (e.g., user info or token)
};

export const loginUser = async ({ phone_number }: { phone_number: string }) => {
  const response = await api.post('/auth/login', { phone_number });
  return response.data; // Return the response data (e.g., user info or token)
};

export const getUserProfile = async () => {
  const response = await api.get('/profile/');
  return response.data;
};

export const fetchNotes = async () => {
  const response = await api.get('/notes/');
  return response.data;
};

export const fetchNoteById = async (noteId: number) => {
  const response = await api.get(`/notes/${noteId}`);
  return response.data;
};

export const updateNote = async (noteId: number, noteData: { content: string }) => {
  const response = await api.put(`/notes/${noteId}`, noteData); // Pass the updated note data in the request
  return response.data;
};

export const createNote = async (note: { content: string }) => {
  const response = await api.post('/notes/', note);
  return response.data;
};


// Add other API functions as needed